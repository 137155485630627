<template>
    <div>

        <el-row type="flex" align="middle">
            <el-col :span="11">
                <i class="el-icon-close icon-gray cursor-pointer" @click="exit"></i>
            </el-col>
            <el-col :span="2">
                <el-steps :active="currentStep" finish-status="success">
                    <el-step></el-step>
                    <el-step></el-step>
                </el-steps>
            </el-col>
            <el-col :span="11">
            </el-col>
        </el-row>

        <el-row class="mt-4">
            <el-col>

                <cakeshops-new-step-two
                    v-if="currentStep===0"
                    :cakeshop-uuid="cakeshop.uuid"
                    @next-step="createCakeFinished"
                    @save-draft="goBack"/>

                <cakeshops-new-step-three
                    v-else-if="currentStep===1"
                    :cakeshop-uuid="cakeshop.uuid"
                    :cake-uuid="cakeshop.cake.uuid"
                    :initial-cake-customizations="cakeshop.cake.customizations"
                    :initial-cake-fillings="cakeshop.cake.fillings"
                    :initial-cake-flours="cakeshop.cake.flours"
                    :initial-cake-toppings="cakeshop.cake.toppings"
                    :initial-cake-sweeteners="cakeshop.cake.sweeteners"
                    :purchasable="cakeshop.cake.purchasable"
                    @save-draft="goBack"
                    @published="cakePublished"/>

            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {messages, dialogs} from '../../../../constants/text-constants';
    import CakeshopsNewStepTwo from '../../CakeshopNew/components/CakeshopsNewStepTwo';
    import CakeshopsNewStepThree from '../../CakeshopNew/components/CakeshopsNewStepThree';

    export default {
        name: "CakeNew",
        components: {
            CakeshopsNewStepTwo,
            CakeshopsNewStepThree
        },
        props: {
            cakeshopId: String
        },
        data() {
            return {
                currentStep: 0,
                cakeshop: {
                    uuid: null,
                    cake: {
                        uuid: null,
                        purchasable: true,
                        customizations: [],
                        fillings: [],
                        flours: [],
                        toppings: [],
                        sweeteners: [],
                    }
                }
            }
        },
        created() {
          this.cakeshop.uuid = this.cakeshopId;
        },
        methods: {
            createCakeFinished(cake) {
                this.cakeshop.cake.uuid = cake.uuid;
                this.cakeshop.cake.purchasable = cake.purchasable;
                this.cakeshop.cake.customizations = cake.customizations;
                this.cakeshop.cake.fillings = cake.fillings;
                this.cakeshop.cake.flours = cake.flours;
                this.cakeshop.cake.toppings = cake.toppings;
                this.cakeshop.cake.sweeteners = cake.sweeteners;
                this.nextStep();
            },
            nextStep() {
                this.currentStep++;
            },
            exit() {
                this.$confirm(dialogs.cake.cancelWizard.text, {
                    confirmButtonText: dialogs.cake.cancelWizard.confirm,
                    cancelButtonText: dialogs.cake.cancelWizard.cancel,
                }).then(() => {
                    this.goBack();
                }).catch(() => {});
            },
            cakePublished() {
                this.$message.success(messages.cake.create.success);
                this.$emit('published');
            },
            goBack() {
                this.$emit('cancel');
            },
        }
    }
</script>

<style scoped>
    .el-icon-close {
        font-size: 18px;
    }
</style>
