<template>

    <el-form
        ref="cakeshopAddressForm"
        :model="cakeshopAddress"
        :rules="rules"
        size="medium"
        :status-icon="true"
        :inline-message="true"
        :hide-required-asterisk="true"
        label-position="top"
        @submit.prevent>
        <el-row :gutter="20">

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            DIRECCIÓN COMPLETA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="address">
                            <template slot="label">
                                Dirección*
                            </template>
                            <el-input
                                v-model="cakeshopAddress.address"
                                placeholder="Ej. Calle Delicias, 3"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="postalCode">
                            <template slot="label">
                                Código Postal*
                            </template>
                            <el-input
                                v-model.number="cakeshopAddress.postalCode"
                                placeholder="Ej. 28004"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="city">
                            <template slot="label">
                                Ciudad*
                            </template>
                            <city-selector v-model="cakeshopAddress.city"></city-selector>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="zone">
                            <template slot="label">
                                Zona
                            </template>
                            <el-input
                                v-model="cakeshopAddress.zone"
                                placeholder="Ej. Serrano"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="phone">
                            <template slot="label">
                                Teléfono
                            </template>
                            <el-input
                                v-model.number="cakeshopAddress.phone"
                                placeholder="Ej. 956789345"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            HORARIOS
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="openDays">
                            <template slot="label">
                                Dias apertura
                            </template>
                            <el-input
                                v-model="cakeshopAddress.openDays"
                                placeholder="Ej. Lunes-Viernes"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="openHours">
                            <template slot="label">
                                Horas apertura
                            </template>
                            <el-input
                                v-model="cakeshopAddress.openHours"
                                placeholder="Ej. 11:30-14:00 / 17:00-21:00"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            PERSONA RESPONSABLE
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="contactName">
                            <template slot="label">
                                Nombre
                            </template>
                            <el-input
                                v-model="cakeshopAddress.contactName"
                                placeholder="Ej. Juan Martin"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="contactPhone">
                            <template slot="label">
                                Móvil
                            </template>
                            <el-input
                                v-model.number="cakeshopAddress.contactPhone"
                                placeholder="Ej. 678594736"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-5">
                    <el-col>
                        <div class="legend">*Obligatorio</div>
                    </el-col>
                </el-row>
            </el-col>

        </el-row>
    </el-form>
</template>

<script>
    import CitySelector from '../CitySelector';

    export default {
        name: "CakeshopAddressForm",
        components: {
            CitySelector
        },
        props: {
            doFormSubmit: {
                type: Boolean
            },
            initialAddress: {
                type: Object,
                default: function () {
                    return {
                        address: null,
                        postalCode: null,
                        city: null,
                        zone: null,
                        contactName: null,
                        contactPhone: null,
                        openDays: null,
                        openHours: null,
                    }
                }
            },
        },
        data() {

            let postalCode = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('Campo obligatorio'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(parseInt(value)) || value < 0) {
                        callback(new Error('No es un valor numérico'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            let phoneValidator = (rule, value, callback) => {
                if (!value) {
                    return callback();
                }
                setTimeout(() => {
                    if (!Number.isInteger(parseInt(value))) {
                        callback(new Error('No es un valor numérico'));
                    } else if (!/^([6-9])\d{8}$/.test(value)) {
                        callback(new Error('Número no válido'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            return {
                cakeshopAddress: {
                    address: null,
                    postalCode: null,
                    city: null,
                    zone: null,
                    contactName: null,
                    contactPhone: null,
                    openDays: null,
                    openHours: null,
                },
                rules: {
                    address: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 5, message: 'Longitud mínima de 5 caracteres', trigger: 'blur'}
                    ],
                    postalCode: [
                        {validator: postalCode, trigger: 'blur'}
                    ],
                    city: [
                        {required: true, message: 'Campo obligatorio', trigger: 'change'}
                    ],
                    phone: [
                        {validator: phoneValidator, trigger: 'blur'}
                    ],
                    contactPhone: [
                        {validator: phoneValidator, trigger: 'blur'}
                    ]
                },
            }
        },
        created() {
            this.fillFormWithInitialValues();
        },
        methods: {
            fillFormWithInitialValues() {
                this.cakeshopAddress = Object.assign({}, this.cakeshopAddress, this.initialAddress);
            },
            submitForm() {
                this.$refs['cakeshopAddressForm'].validate((valid) => {
                    if (valid) {
                        this.$emit('submit', this.cakeshopAddress);
                    } else {
                        this.$emit('not-valid');
                        return false;
                    }
                });
            }
        },
        watch: {
            doFormSubmit: function () {
                if (this.doFormSubmit) {
                    this.submitForm();
                }
            },
            initialAddress: function () {
                this.fillFormWithInitialValues();
            },
        }
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 0.5rem; /* equal to 'mb-2' class */
    }

    >>> label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .legend {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5D5039;
    }
</style>
