import request from '@/utils/request'

export function getCakeshopAddresses(cakeshopid) {
    return request.get('/cakeshops/' + cakeshopid + '/addresses')
}

export function changeCakeshopAddressAvailability(cakeshopId, addressId, available) {
    return request.post('/cakeshops/' + cakeshopId + '/addresses/' + addressId + '/availability', {
        available: available
    });
}

export function createCakeshopAddress(cakeshopId, address) {
    const url = '/cakeshops/' + cakeshopId + '/addresses';
    return request.post(url, address);
}

export function updateCakeshopAddress(cakeshopId, addressId, address) {
    const url = '/cakeshops/' + cakeshopId + '/addresses/' + addressId;
    return request.post(url, address);
}

export function deleteCakeshopAddress(cakeshopId, addressId) {
    const url = '/cakeshops/' + cakeshopId + '/addresses/' + addressId;
    return request.delete(url);
}
