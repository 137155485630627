<template>

    <div class="cakeshop-profile">

        <div class="mt-5">

            <el-row type="flex" align="middle">
                <el-col :span="8">
                    <div v-if="role === 'ADMIN'" class="breadcrumbs" @click="goBack">
                        <svg-icon class="mr-1" icon-class="back-arrow"/>
                        Listado Pastelerías
                    </div>
                </el-col>
                <el-col :span="8" class="text-center"><span class="uppercase-title">{{cakeshop.name}}</span></el-col>
                <el-col :span="8" class="text-right">
                    <div v-if="role === 'ADMIN'">
                        <span><svg-icon class="mr-1" icon-class="start"/>{{cakeshop.rate}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="phone"/>{{cakeshop.managerPhone}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="location"/>{{cakeshop.city.name}}</span>
                    </div>
                </el-col>
            </el-row>

        </div>

        <div class="mt-4 pt-3 pb-4 px-5 bg-white border border-gray rounded">

            <el-row class="d-flex flex-row justify-content-between" v-if="!newCake && !newAddress">

                <el-col>
                    <el-menu :default-active="activeIndex" @select="handleSelectTab" mode="horizontal">
                        <el-menu-item index="1">
                            Creaciones
                        </el-menu-item>
                        <el-menu-item index="2">
                            Perfil
                        </el-menu-item>
                        <el-menu-item index="3">
                            Direcciones
                        </el-menu-item>
                    </el-menu>
                </el-col>

                <el-col class="d-flex flex-row justify-content-end pt-3">

                    <template v-if="activeIndex === '1'">
                        <div>
                            <el-button type="info" @click="newCake = true">Nueva Creación</el-button>
                        </div>
                    </template>

                    <template v-else-if="activeIndex === '2' && editing">
                        <div>
                            <el-button type="text" @click="changeEditingView(false)">
                                Cancelar
                            </el-button>
                        </div>

                        <div>
                            <el-button
                                type="success"
                                :loading="loading"
                                :disabled="photoLoading"
                                @click="requestCakeshop">
                                Guardar
                            </el-button>
                        </div>
                    </template>

                    <template v-else-if="activeIndex === '2' && !editing">
                        <div>
                            <el-button :disabled="!cakeshop.name" @click="changeEditingView(true)">
                                Editar Perfil
                            </el-button>
                        </div>
                    </template>

                    <template v-else-if="activeIndex === '3'">
                        <div>
                            <el-button type="info" @click="newAddress = true">Nueva Dirección</el-button>
                        </div>
                    </template>

                </el-col>

            </el-row>

            <el-row class="mt-4">
                <el-col>

                    <cake-list
                        ref="cakeList"
                        v-show="activeIndex === '1'  && !newCake"
                        :cakeshop-id="$route.params.uuid"/>

                    <cake-new
                        v-if="activeIndex === '1' && newCake"
                        @cancel="reloadCakeList"
                        @published="reloadCakeList"
                        :cakeshop-id="$route.params.uuid"/>

                    <cakeshop-detail
                        v-if="activeIndex === '2' && !editing"
                        :cakeshop="cakeshop"
                        @deleted="goBack"
                        @updated="refreshCakeshop"/>

                    <cakeshop-form
                        v-if="cakeshop.name"
                        v-show="activeIndex === '2' && editing"
                        :do-form-submit="doFormSubmit"
                        :initial-cakeshop="cakeShopForm"
                        :initial-image="base64Image"
                        :image-loading="photoLoading"
                        @submit="saveCakeshop"
                        @not-valid="resetRequest"/>

                    <address-list
                        ref="addressList"
                        v-show="activeIndex === '3' && !newAddress"
                        :cakeshop-id="$route.params.uuid"/>

                    <address-new
                        v-if="activeIndex === '3' && newAddress"
                        :cakeshop-id="$route.params.uuid"
                        @finish="reloadAddressList"/>

                </el-col>
            </el-row>

        </div>

    </div>

</template>

<script>
    import {messages} from '../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import CakeshopDetail from "./components/CakeshopDetail";
    import CakeshopForm from "../../../components/CakeshopForm/index";
    import CakeList from "./components/CakeList";
    import {updateCakeshop} from "../../../api/cakeshop-api";
    import {getBase64Media} from '../../../api/media-api';
    import CakeNew from "./components/CakeNew";
    import AddressList from "./components/AddressList";
    import AddressNew from "./components/AddressNew";

    export default {
        name: "CakeshopProfile",
        components: {
            AddressNew,
            AddressList,
            CakeNew,
            CakeList,
            CakeshopForm,
            CakeshopDetail,
        },
        data() {
            return {
                activeIndex: '1',
                editing: false,
                newCake: false,
                newAddress: false,
                cakeshop: {
                    city: {}
                },
                base64Image: null,
                photoLoading: false,
                doFormSubmit: false,
                loading: false
            };
        },
        created() {
            if (this.$route.params.uuid) {
                this.cakeshop = Object.assign({}, this.cakeshopById(this.$route.params.uuid));
                this.activeIndex = this.$route.params.tab != null ? this.$route.params.tab : '1';
                if (!this.cakeshop.uuid) {
                    this.showGetError();
                    this.goBack();
                }
            } else {
                this.showGetError();
                this.goBack();
            }
        },
        computed: {
            ...mapGetters([
                'cakeshopById',
                'role'
            ]),
            cakeShopForm() {
                return {
                    name: this.cakeshop.name,
                    description: this.cakeshop.description,
                    city: this.cakeshop.city,
                    rate: this.cakeshop.rate,
                    managerName: this.cakeshop.managerName,
                    managerPhone: parseInt(this.cakeshop.managerPhone),
                    address: this.cakeshop.address,
                    phone: parseInt(this.cakeshop.phone),
                    contactPersonName: this.cakeshop.contactName,
                    contactPersonPhone: (this.cakeshop.contactPhone ? parseInt(this.cakeshop.contactPhone) : null)
                }
            }
        },
        methods: {
            changeEditingView(editing) {
                this.editing = editing;
            },
            handleSelectTab(index) {
                this.activeIndex = index;
            },
            showGetError() {
                this.$message.error(messages.cakeshop.get.error);
            },
            goBack() {
                if (this.role === 'BAKER') {
                    this.$router.push({name: 'baker-check'})
                } else {
                    this.$router.push({name: 'cakeshops-list'})
                }
            },
            requestCakeshop() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveCakeshop(cakeshop) {
                let request = {
                    "name": cakeshop.name,
                    "description": cakeshop.description,
                    "rate": cakeshop.rate,
                    "managerName": cakeshop.managerName,
                    "managerPhone": cakeshop.managerPhone,
                    "photo": cakeshop.image
                };

                updateCakeshop(this.cakeshop.uuid, request)
                    .then((response) => {
                        this.$message.success(messages.cakeshop.update.success);
                        this.cakeshop = Object.assign({}, response);
                        this.$store.dispatch('loadCakeshops');
                        this.editing = false;
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshop.update.error);
                    })
                    .finally(() => {
                        this.resetRequest();
                    });
            },
            resetRequest() {
                this.doFormSubmit = false;
                this.loading = false;
            },
            reloadCakeList() {
                this.newCake = false;
                this.$refs.cakeList.loadCakes();
            },
            reloadAddressList() {
                this.newAddress = false;
                this.$refs.addressList.loadAddresses();
            },
            refreshCakeshop(cakeshop) {
                this.cakeshop = Object.assign({}, cakeshop);
                this.$store.dispatch('loadCakeshops');
            }
        },
        watch: {
            cakeshop: function () {
                if (!this.base64Image && this.cakeshop.photoId) {
                    this.photoLoading = true;
                    getBase64Media(this.cakeshop.photoId)
                        .then(response => {
                            this.base64Image = response.base64;
                        })
                        .finally(() => {
                            this.photoLoading = false;
                        });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import '../../../assets/styles/variables.scss';

    .el-menu {
        max-width: 450px;
    }

    .el-menu-item {
        width: 150px;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 12px;
        letter-spacing: 0.1em;
        color: $primary-text-color;
        text-transform: uppercase;
    }

    .el-menu--horizontal > .el-menu-item {
        height: 45px;
    }

    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid $secondary-color-b;
        font-weight: 600;
        color: $primary-text-color;
    }

    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        outline: 0;
        color: $primary-text-color !important;
    }

</style>
