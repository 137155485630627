<template>
    <div>

        <el-row type="flex" align="top" style="margin-top: -8px">

            <el-col :span="8">
                <i class="el-icon-close icon-gray cursor-pointer" @click="exit"></i>
            </el-col>

            <el-col :span="8" class="text-center"> <span class="box-title">Nueva Dirección</span></el-col>

            <el-col :span="8" class="d-flex flex-row justify-content-end">
                <el-button
                    type="success"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm">Guardar
                </el-button>
            </el-col>

        </el-row>

        <el-row class="mt-4">
            <el-col>

                <cakeshop-address-form
                    :do-form-submit="doFormSubmit"
                    @submit="createCakeshopAddressRequest"/>

            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {dialogs, messages} from "../../../../constants/text-constants";
    import CakeshopAddressForm from "../../../../components/CakeshopAddressForm/index";
    import {createCakeshopAddress} from '@/api/cakeshop-address-api';

    export default {
        name: "AddressNew",
        components: {CakeshopAddressForm},
        props: {
            cakeshopId: String
        },
        data() {
          return {
              loading: false,
              doFormSubmit: false
          }
        },
        methods: {
            exit() {
                this.$confirm(dialogs.cakeshopAddress.cancelNewAddress.text, {
                    confirmButtonText: dialogs.cakeshopAddress.cancelNewAddress.confirm,
                    cancelButtonText: dialogs.cakeshopAddress.cancelNewAddress.cancel,
                }).then(() => {
                    this.goBack();
                }).catch(() => {});
            },
            goBack() {
                this.$emit('finish');
            },
            createCakeshopAddressRequest(cakeshopAddress) {
                this.loading = true;
                let request = {
                    address: cakeshopAddress.address,
                    postalCode: cakeshopAddress.postalCode,
                    cityId: cakeshopAddress.city.uuid,
                    zone: cakeshopAddress.zone,
                    contactName: cakeshopAddress.contactName,
                    contactPhone: cakeshopAddress.contactPhone,
                    openDays: cakeshopAddress.openDays,
                    openHours: cakeshopAddress.openHours,
                };
                createCakeshopAddress(this.cakeshopId, request)
                    .then(() => {
                        this.goBack();
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshopAddress.create.error);
                    })
                    .finally(() => {
                        this.doFormSubmit = false;
                        this.loading = false;
                    });
            },
            submitForm() {
                this.doFormSubmit = true;
            },
        }
    }
</script>

<style scoped>

</style>
