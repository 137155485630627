<template>

    <div v-loading="loading">

        <el-row :gutter="20">

            <el-col :span="9">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            DESCRIPCIÓN GENERAL
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <label>
                            Nombre
                        </label>
                        <div>{{cakeshop.name}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Descripción
                        </label>
                        <div>{{cakeshop.description}}</div>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'ADMIN'" class="mt-4">
                    <el-col>
                        <label>
                            Puntuación
                        </label>
                        <div>{{cakeshop.rate}}</div>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :span="6">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            RESPONSABLE PASTELERÍA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <label>
                            Nombre
                        </label>
                        <div>{{cakeshop.managerName}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Móvil
                        </label>
                        <div>{{cakeshop.managerPhone}}</div>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="9">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            IMAGEN DESTACADA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <image-slider :image-urls="cakeshop.photo ? [cakeshop.photo] : []"></image-slider>
                    </el-col>
                </el-row>

                <div v-show="role === 'ADMIN'">
                    <el-row class="mt-5 py-1" type="flex" align="middle">
                        <el-col>
                            <el-switch
                                class=""
                                :value="cakeshop.status === 'PUBLISHED'"
                                @change="changeCakeshopStatus"/>
                            <span v-if="cakeshop.status === 'PUBLISHED'" class="pt-1 ml-2">Pastelería publicada</span>
                            <span v-else class="pt-1 ml-2">Pastelería no publicada</span>
                        </el-col>
                    </el-row>

                    <el-row class="mt-3">
                        <el-col class="cursor-pointer">
                            <div @click="confirmDeleteCakeshop">
                                <svg-icon icon-class="delete"/>
                                <span class="ml-2">Eliminar Pastelería</span>
                            </div>
                        </el-col>
                    </el-row>
                </div>

            </el-col>

        </el-row>

    </div>

</template>

<script>
    import {messages, dialogs} from '../../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import {disableCakeshop, activateCakeshop, deleteCakeshop} from "@/api/cakeshop-api";
    import ImageSlider from "../../../../components/ImageSlider";

    export default {
        name: "CakeshopDetail",
        components: {
            ImageSlider
        },
        props: [
            "cakeshop"
        ],
        data() {
            return {
                loading: false
            };
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
        },
        methods: {
            changeCakeshopStatus(checked) {
                if (checked) {
                    this.enableCakeshop();
                } else {
                    this.disableCakeshop();
                }
            },
            async enableCakeshop() {
                this.$confirm(dialogs.cakeshop.enable.text, {
                    confirmButtonText: dialogs.cakeshop.enable.confirm,
                    cancelButtonText: dialogs.cakeshop.enable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.loading = true;
                    activateCakeshop(this.cakeshop.uuid).then((cakeshop) => {
                        this.$emit('updated', cakeshop);
                        this.loading = false;
                        this.showMessageChangeCakeshopStatusSuccess();
                    }).catch(() => {
                        this.loading = false;
                        this.showMessageChangeCakeshopStatusError();
                    });

                }).catch(() => {});
            },
            async disableCakeshop() {
                this.$confirm(dialogs.cakeshop.disable.text, {
                    confirmButtonText: dialogs.cakeshop.disable.confirm,
                    cancelButtonText: dialogs.cakeshop.disable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.loading = true;
                    disableCakeshop(this.cakeshop.uuid).then((cakeshop) => {
                        this.$emit('updated', cakeshop);
                        this.loading = false;
                        this.showMessageChangeCakeshopStatusSuccess();
                    }).catch(() => {
                        this.loading = false;
                        this.showMessageChangeCakeshopStatusError();
                    });

                }).catch(() => {
                });
            },
            showMessageChangeCakeshopStatusSuccess() {
                this.$message.success(messages.cakeshop.status.success);
            },
            showMessageChangeCakeshopStatusError() {
                this.$message.error(messages.cakeshop.status.error);
            },
            confirmDeleteCakeshop() {
                this.$confirm(dialogs.cakeshop.cancel.text, {
                    confirmButtonText: dialogs.cakeshop.cancel.confirm,
                    cancelButtonText: dialogs.cakeshop.cancel.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.deleteCakeshop();
                }).catch(() => {
                });
            },
            deleteCakeshop() {
                this.loading = true;
                deleteCakeshop(this.cakeshop.uuid).then(() => {
                    this.loading = false;
                    this.$message.success(messages.cakeshop.delete.success);
                    this.$emit('deleted');
                }).catch(() => {
                    this.loading = false;
                    this.$message.error(messages.cakeshop.delete.error);
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .cursor-pointer :hover {
        font-weight: bold;

        .svg-icon {
            width: 1.2em;
            height: 1.2em;
        }
    }
</style>
