<template>
    <el-table
        v-loading="loading"
        class="table m-0"
        @row-click="goToCakeDetail"
        :row-class-name="'cursor-pointer'"
        :data="cakes"
        emptyText="Completa tu primera creación para poder publicar tu pastelería en Wecake"
        ref="cakesTable">

        <el-table-column
            v-if="role === 'ADMIN'"
            label="Disponibilidad"
            width="150">

            <template slot-scope="data">
                <el-switch
                    :value="data.row.status"
                    active-value="PUBLISHED"
                    inactive-value="INACTIVE"
                    :disabled="cakeDisabled(data.row.status)"
                    @change="changeCakeStatus(data.row, $event)"/>
            </template>
        </el-table-column>

        <el-table-column
            label="Nombre"
            prop="name"
            width="400">

            <template slot-scope="data">
                <span class="table-cell-name">{{data.row.name}}</span><span v-if="!data.row.purchasable"> - No Comprable</span>
            </template>
        </el-table-column>

        <el-table-column
            label="Estado"
            prop="status"
            :filters="[{text: 'Publicada', value: 'PUBLISHED'}, {text: 'Inactiva', value: 'INACTIVE'}, {text: 'Borrador', value: 'DRAFT'}]"
            :filter-method="filterHandler">

            <template slot-scope="data">
                <publish-status style="font-size: 12px" :status="data.row.status"/>
            </template>
        </el-table-column>

        <el-table-column align="right" width="50">
            <template slot="header">
                <svg-icon
                    style="width: 1.5em; height: 1.5em;"
                    icon-class="reset"
                    class="cursor-pointer"
                    @click="resetFilters"/>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
    import {messages, dialogs} from '@/constants/text-constants';
    import {getCakes, activateCake, disableCake} from '@/api/cake-api';
    import {mapGetters} from 'vuex';
    import PublishStatus from "../../../../components/PublishStatus/index";

    export default {
        name: "CakeList",
        components: {PublishStatus},
        props: {
            cakeshopId: String
        },
        data() {
            return {
                cakes: [],
                loading: false
            };
        },
        mounted() {
            this.loadCakes();
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
        },
        methods: {
            loadCakes() {
                getCakes(this.cakeshopId)
                    .then(cakes => {
                        this.cakes = cakes;
                        this.loading = false;
                        if (!this.hasActiveCakes() && this.role === 'BAKER') {
                            this.$confirm(dialogs.cakeshop.newBaker.text, {
                                confirmButtonText: dialogs.cakeshop.newBaker.confirm,
                                showCancelButton: false,
                                showClose: false,
                                closeOnClickModal: false,
                                dangerouslyUseHTMLString: true,
                            }).then(() => {}).catch(() => {});
                        }
                    })
                    .catch(() => {
                        this.$message.error(messages.cake.list.error);
                    });
            },
            hasActiveCakes() {
                return this.cakes.length !== 0
                    && (this.cakes.some(cake => cake.status === 'INACTIVE') || this.cakes.some(cake => cake.status === 'PUBLISHED'))
            },
            goToCakeDetail(row, _, event) {
                if (event.target.className !== 'el-switch__core') {
                    this.$router.push({ name: 'cake-profile', params: { cakeId: row.uuid, cakeshopId: this.cakeshopId  } })
                }
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            resetFilters() {
                this.$refs.cakesTable.clearFilter();
            },
            cakeDisabled(cakeStatus) {
                return cakeStatus === 'DRAFT';
            },
            changeCakeStatus(cake, value) {
                if (value === 'PUBLISHED') {
                    this.activateCake(cake.uuid);
                } else {
                    this.disableCake(cake.uuid);
                }
            },
            activateCake(cakeId) {
                this.$confirm(dialogs.cake.enable.text, {
                    confirmButtonText: dialogs.cake.enable.confirm,
                    cancelButtonText: dialogs.cake.enable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.cakes = [];
                    this.loading = true;
                    activateCake(cakeId).then(() => {
                        this.loadCakes();
                        this.showMessageChangeCakeStatusSuccess();
                    });
                }).catch(() => {
                });
            },
            disableCake(cakeId) {
                this.$confirm(dialogs.cake.disable.text, {
                    confirmButtonText: dialogs.cake.disable.confirm,
                    cancelButtonText: dialogs.cake.disable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.cakes = [];
                    this.loading = true;
                    disableCake(cakeId).then(() => {
                        this.loadCakes();
                        this.showMessageChangeCakeStatusSuccess();
                    });
                }).catch(() => {
                });
            },
            showMessageChangeCakeStatusSuccess() {
                this.$message.success(messages.cake.status.success);
            }
        }
    }
</script>

<style scoped>
    >>> th.is-leaf {
        background-color: white !important;
    }

    .cake-check {
        transform: scale(1.5);
    }
</style>
