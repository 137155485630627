<template>
    <el-table
        v-loading="loading"
        class="table m-0"
        @row-click="goToAddressDetail"
        :row-class-name="'cursor-pointer'"
        :data="addresses"
        ref="addressesTable">

        <el-table-column
            v-if="role === 'ADMIN'"
            label="Disponibilidad"
            width="150">

            <template slot-scope="data">
                <el-switch
                    :value="data.row.available"
                    @change="changeAddressAvailability(data.row, $event)"/>
            </template>
        </el-table-column>

        <el-table-column
            label="Dirección"
            prop="address"
            width="400">

            <template slot-scope="data">
                    <span class="table-cell-name">{{data.row.address}}</span>
            </template>
        </el-table-column>

        <el-table-column
            label="Código Postal"
            prop="postalCode">

            <template slot-scope="data">
                <span class="table-cell-name">{{data.row.postalCode}}</span>
            </template>
        </el-table-column>

        <el-table-column
            label="Ciudad"
            prop="city.name"
            :filters="citiesFilter"
            :filter-method="filterCities">
        </el-table-column>

        <el-table-column align="right" width="50">
            <template slot="header">
                <svg-icon
                    style="width: 1.5em; height: 1.5em;"
                    icon-class="reset"
                    class="cursor-pointer"
                    @click="resetFilters"/>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
    import {messages, dialogs} from '../../../../constants/text-constants';
    import {getCakeshopAddresses, changeCakeshopAddressAvailability} from '@/api/cakeshop-address-api';
    import {mapGetters} from 'vuex';

    export default {
        name: "AddressList",
        props: {
            cakeshopId: String
        },
        data() {
            return {
                addresses: [],
                loading: false
            };
        },
        mounted() {
            this.loadAddresses();
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
            citiesFilter() {
                let cities = this.addresses.map(item => item.city.name);
                cities = cities.filter((value, index, self) => self.indexOf(value) === index);
                return cities.map(item => {
                    return {text: item, value: item}
                })
            }
        },
        methods: {
            loadAddresses() {
                getCakeshopAddresses(this.cakeshopId)
                    .then(addresses => {
                        this.addresses = addresses;
                        this.loading = false;
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshopAddress.list.error);
                    });
            },
            goToAddressDetail(row, _, event) {
                if (event.target.className !== 'el-switch__core') {
                    this.$router.push({ name: 'cakeshop-address-profile', params: { cakeshopAddress: row, cakeshopId: this.cakeshopId  } })
                }
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            resetFilters() {
                this.$refs.addressesTable.clearFilter();
            },
            changeAddressAvailability(address, available) {
                this.$confirm(this.addresses.length === 1
                    ? dialogs.cakeshopAddress.changeAvailability.textOneLeft
                    : dialogs.cakeshopAddress.changeAvailability.text, {
                    confirmButtonText: dialogs.cakeshopAddress.changeAvailability.confirm,
                    cancelButtonText: dialogs.cakeshopAddress.changeAvailability.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.addresses = [];
                    this.loading = true;
                    changeCakeshopAddressAvailability(this.cakeshopId, address.uuid, available)
                        .then(() => {
                            this.loadAddresses();
                            this.showMessageChangeAddressAvailabilitySuccess();
                        });
                }).catch(() => {
                });
            },
            filterCities(value, row) {
                return row.city.name === value;
            },
            showMessageChangeAddressAvailabilitySuccess() {
                this.$message.success(messages.cakeshopAddress.status.success);
            }
        }
    }
</script>

<style scoped>
    >>> th.is-leaf {
        background-color: white !important;
    }

    .cake-check {
        transform: scale(1.5);
    }
</style>
